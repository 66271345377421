@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background-color: #fff;
  font-family: 'Poppins', sans-serif;
  /* prevents the blue highlight when clicking on buttons/links */
  -webkit-tap-highlight-color: transparent;
}

section {
  min-height: 90vh;
}

input[disabled] {
  background: #dddddd;
  cursor: not-allowed;
}


@media (min-width: 640px) {
  #home__hero {
    min-height: auto;
  }
}

#home__discover {
  background-image: url("/images/home/home-discover.png");
  height: 100%;
  min-height: 65vh;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 640px) {
  #home__discover {
    background-position: bottom;
  }
}

#schedule__demo {
  background-image: url("/images/schedule-demo-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
